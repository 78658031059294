import Choices from "choices.js";

class Choice {
  /**
   * 初始化 Choices 组件， 全局使用
   */
  static init() {
    const choices = document.getElementsByClassName("js-choices");
    for (let index = 0; index < choices.length; index++) {
      const element = choices[index];
      const searchEnabled =
        element.getAttribute("data-search-enabled") &&
        element.getAttribute("data-search-enabled") === "false"
          ? false
          : true;
      const removeItem =
        element.getAttribute("data-remove-item") &&
        element.getAttribute("data-remove-item") === "false"
          ? false
          : true;

      const choiceInited = element.getAttribute("data-choice-inited");
      if (!choiceInited) {
        const choiceInstance = new Choices(element, {
          removeItemButton: removeItem,
          itemSelectText: "",
          searchPlaceholderValue: "Search ...",
          searchEnabled: searchEnabled,
          searchFields: ["label"],
          searchResultLimit: 10,
          shouldSort: false,
          callbackOnCreateTemplates: function (template) {
            return {
              choice: (classNames, data) => {
                const iconGray = `
                  <svg class="item-icon-gray mr-2 flex-shrink-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#F2F5F9"/>
                    <path d="M7.00012 9.586L11.5961 4.9895L12.3036 5.6965L7.00012 11L3.81812 7.818L4.52512 7.111L7.00012 9.586Z" fill="#635BFF"/>
                  </svg>
                `;
                const iconPrimary = data.placeholder ? `
                  <svg class="item-icon-primary mr-2 flex-shrink-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#F2F5F9"/>
                    <path d="M7.00012 9.586L11.5961 4.9895L12.3036 5.6965L7.00012 11L3.81812 7.818L4.52512 7.111L7.00012 9.586Z" fill="#635BFF"/>
                  </svg>
                ` : `
                  <svg class="item-icon-primary mr-2 flex-shrink-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#635BFF"/>
                    <path d="M6.99999 9.586L11.596 4.9895L12.3035 5.6965L6.99999 11L3.81799 7.818L4.52499 7.111L6.99999 9.586Z" fill="white"/>
                  </svg>
                `;
                return template(`
                  <div class="${classNames.item} ${classNames.itemChoice} ${
                  data.disabled
                    ? classNames.itemDisabled
                    : classNames.itemSelectable
                } ${data.placeholder ? classNames.placeholder : ""}"
                  data-select-text="${
                    this.config.itemSelectText
                  }" data-choice ${
                  data.disabled
                    ? 'data-choice-disabled aria-disabled="true"'
                    : "data-choice-selectable"
                } data-id="${data.id}" data-value="${data.value}" ${
                  data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                }>
                    <div class="flex items-center">
                      ${element.multiple ? iconGray : ''}
                      ${element.multiple ? iconPrimary : ''}
                      <span class="item-text">
                        ${data.label}
                      </span>
                    </div>
                  </div>
                `);
              },
            };
          },
        });

        element.setAttribute("data-choice-inited", true);
        storeToWindow(element, choiceInstance);

        // portfolio 页面的搜索添加回调函数，提交表单
        if (
          element.id === "FisheryFilter" ||
          element.id === "AssetsTypeFilter"
        ) {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              // const msg = `You select: ${data.value}`;
              // console.log(data, msg);
              if (data.value) {
                // 访问新页面，如果不使用turbo，则需要使用 window.location.href
                // Turbo.visit(`/portfolio?fishery_id=${data.value}`);
                // 由于出现多个参数，遂改为form提交
                const form = document.getElementById("AssetsFilterForm");
                if (form) {
                  form.submit();
                }
              }
            }
          );
        }

        // portfolio 页面的搜索, 按照 entity 搜索 fishery，提交表单
        if (element.id === "PortfolioEntityFilter") {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              // const msg = `You select: ${data.value}`;
              // console.log(data, msg);
              if (data.value) {
                const form = document.getElementById("PortfolioFisheryFilterForm");
                if (form) {
                  form.submit();
                }
              }
            }
          );
        }

        // inventory 页面的搜索添加回调函数，提交表单
        if (
          element.id === "InventoryFisheryFilter" ||
          element.id === "InventoryEntityFilter"
        ) {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              if (data.value) {
                const form = document.getElementById("InventoryFilterForm");
                if (form) {
                  form.submit();
                }
              }
            }
          );
        }

        // new inventory 页面的回调函数，显示隐藏对应的金额输入框
        if (element.id === "AmountTypeSelect") {
          choiceInstance.passedElement.element.addEventListener(
            "choice",
            function (evt) {
              const data = evt.detail.choice || {};
              // const msg = `You select: ${data.value}`;
              // console.log(data, msg);
              toggleValueType(data);
            }
          );
        }

        // new  inventory 页面的回调函数, ajax 选择 fishing season / zone
        if (element.id === "inventory_fishing_season_id") {
          choiceInstance.passedElement.element.addEventListener(
            "choice",
            function (evt) {
              const data = evt.detail.choice || {};
              setInventeroryZones(data);
            }
          );
        }

        // new order 页面，买家 entity 选中之后的回调，设置一个买家（人）。
        if (element.id === "BuyerEntitySelect") {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              setContactChoiceItems(data.value);
            }
          );
        }

        // new order 页面的 fishery 选中回调函数，过滤 quota unit
        // if (element.classList.contains("js-unit-by-fishery")) {
        //   choiceInstance.passedElement.element.addEventListener(
        //     "choice",
        //     function (evt) {
        //       const data = evt.detail.choice || {};
        //       setQuotaUnitItems(data, element);
        //     }
        //   );
        // }

        // new order 页面，选择一个asset type后，显示对应的下拉选择数据框
        if (element.classList.contains("js-toggle-asset")) {
          choiceInstance.passedElement.element.addEventListener(
            "choice",
            function (evt) {
              toggleAssetsTypes(evt, element);
            }
          );
        }

        // new order 页面的 quota unit 选中回调函数，过滤 fishing season
        if (element.classList.contains("js-season-by-unit")) {
          choiceInstance.passedElement.element.addEventListener(
            "choice",
            function (evt) {
              const data = evt.detail.choice || {};
              setFishingSeasonItems(data, element);
            }
          );
        }

        // new entitlement 页面的 fishery 选中回调函数，设置 license
        if (element.id === "FisherySelect") {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              setLicenseChoiceItems(data.value);
            }
          );
        }

        // new quota unit 页面，如果url没有fishery_id时，选择一个fishery，然后提交
        if (element.id === "FisheryPreSelect") {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              if (data.value) {
                const form = document.getElementById("FisheryPreForm");
                if (form) {
                  form.submit();
                }
              }
            }
          );
        }

        // license show 页面，批量添加/减少库存
        if (element.id === "BulkQuotaUnitInventorySelect") {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              setBulkInventoryZoneItems(data.value);
            }
          );
        }

        // new payment 页面，根据 fishery，过滤 fishing season
        if (element.id === "BillFisherySelect") {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              setPaymentFishingSeasonItems(data.value);
            }
          );
        }

        // layout 中过滤 momes
        if (element.classList.contains("js-filter-memos")) {
          choiceInstance.passedElement.element.addEventListener(
            "change",
            function (evt) {
              const data = evt.detail || {};
              // const msg = `You select: ${data.value}`;
              // console.log(data, msg);
              if (data.value) {
                const form = document.getElementById("MemosFilterForm");
                if (form) {
                  form.submit();
                }
              }
            }
          );
        }

        // other 页面的搜索添加回调函数
        if (element.id === "XxxxFilter") {
        }
      }
    }
  }
}

function storeToWindow(ele, choice) {
  let keyChoice;
  if (ele.id) {
    keyChoice = ele.id;
    window["ChoiceStorage"] = window["ChoiceStorage"] || {};
    window["ChoiceStorage"][`${keyChoice}`] = choice;
  }
}

function getFromWindow(k) {
  const obj = window["ChoiceStorage"] || {};
  return obj[`${k}`];
}

// Inventory new page
function toggleValueType(data) {
  const debitFieldDom = document.getElementsByClassName("js-show-debit");
  const creditFieldDom = document.getElementsByClassName("js-show-credit");
  const value = data.value;
  if (value && value === "debit") {
    for (let index = 0; index < debitFieldDom.length; index++) {
      const debitField = debitFieldDom[index];
      debitField.style.display = "block";
    }
    for (let index = 0; index < creditFieldDom.length; index++) {
      const creditField = creditFieldDom[index];
      creditField.style.display = "none";
      const creditInput = creditField.querySelector("input");
      creditInput.value = "";
    }
  }
  if (value && value === "credit") {
    for (let index = 0; index < debitFieldDom.length; index++) {
      const debitField = debitFieldDom[index];
      debitField.style.display = "none";
      const debitInput = debitField.querySelector("input");
      debitInput.value = "";
    }
    for (let index = 0; index < creditFieldDom.length; index++) {
      const creditField = creditFieldDom[index];
      creditField.style.display = "block";
    }
  }
}
// Inventory new page
function setInventeroryZones(data) {
  const seasonId = data.value;
  if (seasonId && window.quotaUnitId) {
    const url = `/quota_units/${quotaUnitId}/zones?fishing_season_id=${seasonId}`;
    const choice = getFromWindow("inventory_zone_id");
    if (choice) {
      choice.clearStore();
      choice.setChoices(async () => {
        try {
          const items = await fetch(url);
          return items.json();
        } catch (err) {
          // console.error(err);
        }
      });
    }
  }
}

// Order new page
function setContactChoiceItems(entity_id) {
  const domContacts = document.getElementsByClassName("js-show-by-buyer");
  if (entity_id) {
    domContacts[0].style.display = "block";
  } else {
    domContacts[0].style.display = "none";
  }
  const choice = getFromWindow("BuyerContactSelect");
  if (choice && window.contactList) {
    const data = [
      {
        value: "",
        label: "Please select",
        placeholder: true,
        selected: true,
        entity_id,
      },
    ]
      .concat(contactList)
      .filter((item) => {
        return item.entity_id == entity_id;
      });
    choice.setChoices(data, "value", "label", true);
  }
}
// Order new page
function toggleAssetsTypes(evt, ele) {
  // 切换显示
  const obj = evt.detail.choice || {};
  const parent = evt.target.closest(".js-order-item"); //evt.path[6];

  const list = parent.querySelectorAll(".js-show-by-asset");
  for (let index = 0; index < list.length; index++) {
    const dom = list[index];
    const t = dom.getAttribute("show-for");
    if (t === obj.value) {
      dom.classList.remove("hidden");
    } else {
      dom.classList.add("hidden");
    }
  }
  // 根据 type 设置数据
  const eleId = ele.id || "";
  const domFisherySelectId = eleId.replace("asset_type", "fishery_id");
  const domLicenseSelectId = eleId.replace("asset_type", "license_id");
  const domEntitlementSelectId = eleId.replace("asset_type", "entitlement_id");
  const domQuotaUnitSelectId = eleId.replace("asset_type", "quota_unit_id");
  const domFishery = document.getElementById(domFisherySelectId);
  if (domFishery && domFishery.value) {
    switch (obj.value) {
      case "asset_license":
        setOrderLicenses(domFishery.value, domLicenseSelectId);
        break;
      case "asset_entitlement":
        setOrderEntitlements(domFishery.value, domEntitlementSelectId);
        break;
      case "asset_quota_unit":
        setOrderQuotaUnits(domFishery.value, domQuotaUnitSelectId);
        break;
      default:
        break;
    }
  }
}
// Order new page
function setOrderLicenses(fisheryId, domId) {
  const choice = getFromWindow(domId);
  if (fisheryId && choice) {
    const url = `/fisheries/${fisheryId}/licenses`;
    choice.clearStore();
    choice.setChoices(async () => {
      try {
        const items = await fetch(url);
        return items.json();
      } catch (err) {
        // console.error(err);
      }
    });
  }
}
// Order new page
function setOrderEntitlements(fisheryId, domId) {
  const choice = getFromWindow(domId);
  if (fisheryId && choice) {
    const url = `/fisheries/${fisheryId}/entitlements`;
    choice.clearStore();
    choice.setChoices(async () => {
      try {
        const items = await fetch(url);
        return items.json();
      } catch (err) {
        // console.error(err);
      }
    });
  }
}
// Order new page
function setOrderQuotaUnits(fisheryId, domId) {
  const domZoneSelectId = domId.replace("quota_unit_id", "tacc_zone_id");
  const choiceZone = getFromWindow(domZoneSelectId);
  const choice = getFromWindow(domId);
  if (fisheryId && choice) {
    const url = `/fisheries/${fisheryId}/quota_units`;
    choice.clearStore();
    choiceZone.clearStore();
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const dom = choice.passedElement.element;
        const parent = dom.parentNode.parentNode.parentNode;
        const domZone = choiceZone.passedElement.element;
        const parentZone = domZone.parentNode.parentNode.parentNode;
        const list = result.data || [];
        const blankQuotaUnit = {
          value: "",
          label: "Select quota unit",
          placeholder: true,
          selected: true,
        };
        const blankZone = {
          value: "",
          label: "Select zone",
          placeholder: true,
          selected: true,
        };
        if (result && result.unit_type === "unit") {
          choice.enable();
          choice.setChoices([blankQuotaUnit, ...list]);
          parent.classList.remove("hidden");
          choiceZone.setChoices([blankZone]);
        }
        if (result && result.unit_type === "kg") {
          choice.disable();
          parent.classList.add("hidden");
          choiceZone.setChoices([blankZone, ...list]);
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  }
}

// Order new page
function setFishingSeasonItems(obj, ele) {
  const quotaUnitId = obj.value;
  const eleId = ele.id || "";
  if (quotaUnitId && eleId) {
    const domFishingSeasonId = eleId.replace("quota_unit_id", "tacc_zone_id");
    const choice = getFromWindow(domFishingSeasonId);
    if (choice) {
      const url = `/quota_units/${quotaUnitId}/fishing_season_with_zones_inventory`;
      choice.clearStore();
      choice.setChoices(async () => {
        try {
          const items = await fetch(url);
          return items.json();
        } catch (err) {
          // console.error(err);
        }
      });
    }
  }
}

// License show page
function setBulkInventoryZoneItems(fishing_season_id) {
  const choice = getFromWindow("BulkQuotaUnitZoneSelect");
  if (choice && window.zoneList) {
    const data = [
      {
        value: "",
        label: "Select zone",
        placeholder: true,
        selected: true,
        fishing_season_id,
      },
    ]
      .concat(zoneList)
      .filter((item) => {
        return item.fishing_season_id == fishing_season_id;
      });
    choice.setChoices(data, "value", "label", true);
  }
}

// Entitlement new page
function setLicenseChoiceItems(fishery_id) {
  const choice = getFromWindow("LicenseSelect");
  if (choice && window.licenseList) {
    const data = [
      {
        value: "",
        label: "Please select",
        placeholder: true,
        selected: true,
        fishery_id,
      },
    ]
      .concat(licenseList)
      .filter((item) => {
        return item.fishery_id == fishery_id;
      });
    choice.setChoices(data, "value", "label", true);
  }
}

// Payment new page
function setPaymentFishingSeasonItems(fishery_id) {
  const choice = getFromWindow("BillFishingSeasonSelect");
  if (choice && window.fishingSeasonData) {
    const data = [
      {
        value: "",
        label: "Select fishing season",
        placeholder: true,
        selected: true,
        fishery_id,
      },
    ]
      .concat(fishingSeasonData)
      .filter((item) => {
        return item.fishery_id == fishery_id;
      });
    choice.setChoices(data, "value", "label", true);
  }
}

export default Choice;
